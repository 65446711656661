/* src/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Interactive button styles */
@layer components {
  .interactive-button {
    @apply px-8 py-3 rounded bg-secondary text-textcolor transform transition-transform duration-300;
  }

  .interactive-button:hover {
    @apply bg-primary scale-105;
    transform: perspective(600px) rotateX(10deg) scale(1.05);
  }
}

body {
  @apply bg-background;
}
